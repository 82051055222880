import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import {
  HxCountDownPipe,
  HxCurrencyPipe,
  HxDayDiffPipe,
  HxDiscountUoMPipe,
  HxFioPipe,
  HxFixedPipe,
  HxPriceLabelPipe,
  HxRecipeTypePipe,
  HxTimePipe,
  HxWeightPipe,
  HxSafeHtmlPipe, HxMoneyFormatPipe, HxVatPipe
} from './pipes';
import { HxUiLabelPipe } from './pipes/ui-label.pipe';
import { HxAbbrPipe } from './pipes/abbr.pipe';
import { HxStoreWorkTimePipe } from './pipes/store-work-time.pipe';
import { HxJoinedPipe } from './pipes/joined.pipe';

const components = [
  HxPriceLabelPipe,
  HxWeightPipe,
  HxCurrencyPipe,
  HxFioPipe,
  HxCountDownPipe,
  HxTimePipe,
  HxDiscountUoMPipe,
  HxDayDiffPipe,
  HxUiLabelPipe,
  HxAbbrPipe,
  HxFixedPipe,
  HxStoreWorkTimePipe,
  HxJoinedPipe,
  HxRecipeTypePipe,
  HxSafeHtmlPipe,
  HxMoneyFormatPipe,
  HxVatPipe,
];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components
})
export class HxPipeModule {
}
