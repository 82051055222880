import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { AppPropertyBasicModel, BankAccountInfoModel, OrganizationProperty, PagedList } from '../interface';
import { Currency, OrganizationModel, PaymentType, UiLabel } from '../vn-api';

export interface OrganizationWithUinModel {
  organization: OrganizationModel;
  uin: string;
  uinName: string;
}

@Injectable({
  providedIn: 'root'
})
export class HxOrganizationService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getOrganizations(params?: {
    page?: number,
    limit?: number,
    includeDisabled?: boolean,
    sort?: string[],
    searchText?: string,
    countryId?: number,
    storeIds? : number[],
  }) {
    return firstValueFrom(this.http.get<PagedList<OrganizationWithUinModel>>(`/api/vanilla/organizations`, {params: toHttpParams(params, true)}));
  }

  removeOrganization(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/organizations/${id}`);
  }

  getOrganizationById(id: number) {
    return firstValueFrom(this.http.get<OrganizationModel>(`/api/vanilla/organizations/${id}`));
  }

  getOrganizationByStoreId(storeId: number) {
    return this.http.get<OrganizationModel>(`/api/vanilla/organizations/store/${storeId}`);
  }

  saveOrganization(req: {
    id?: number;
    enabled: boolean;
    title: UiLabel;
    parentId?:number;
    currency: Currency;
    epayOrgId?: number;
    countryId: number;
    paymentTypes: PaymentType[];
    invoicePaymentTypes: PaymentType[];
    info: OrganizationProperty;
    stampFileId?: number;
  }) {
    return firstValueFrom(this.http.post<{ id: number }>(`/api/vanilla/organizations`, req));
  }

  getPropertiesById(id: number): Observable<AppPropertyBasicModel[]> {
    return this.http.get<AppPropertyBasicModel[]>(`/api/vanilla/organizations/${id}/properties`);
  }

  getPropertyByKey(id: number, key: string): Observable<AppPropertyBasicModel> {
    return this.http.get<AppPropertyBasicModel>(`/api/vanilla/organizations/${id}/properties?key=${key}`);
  }

  saveProperty(id: number, req: Partial<AppPropertyBasicModel>) {
    return firstValueFrom(this.http.post<{ id: number }>(`/api/vanilla/organizations/${id}/properties`, req));
  }

  removeProperty(orgId: number, id: number) {
    return firstValueFrom(this.http.delete<void>(`/api/vanilla/organizations/${orgId}/properties/${id}`));
  }

  getPaymentAgreementUploadUrl(orgId: number): string {
    return `/api/vanilla/organizations/${orgId}/payment-agreement`;
  }

  getDebtAllowed(id: number): Observable<AppPropertyBasicModel> {
    return this.http.get<AppPropertyBasicModel>(`/api/vanilla/organizations/${id}/debt-allowed`);
  }

  getMyOrganizations(params: { countryId: number }) {
    return firstValueFrom(this.http.get<OrganizationWithUinModel[]>(`/api/vanilla/organizations/my`, {params: toHttpParams(params, true)}));
  }

  getBankAccounts(orgId: number): Observable<BankAccountInfoModel[]> {
    return this.http.get<BankAccountInfoModel[]>(`/api/vanilla/organizations/${orgId}/bank-accounts`, {});
  }

  saveBankAccount(id: number, body: {
    id?: number;
    bankId?: number;
    iban: string;
    currency: Currency;
    beneficiaryCode: string;
    isDefault: boolean;
    containerId: number;
  }): Observable<{id: number}> {
    return this.http.post<{id: number}>(`/api/vanilla/organizations/${id}/bank-accounts`, body);
  }

  setDefaultBankAccount(id: number, bankAccountId: number): Observable<void> {
    return this.http.post<void>(`/api/vanilla/organizations/${id}/bank-accounts/${bankAccountId}/default`, {});
  }

  deleteBankAccount(id: number, bankAccountId: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/organizations/${id}/bank-accounts/${bankAccountId}`);
  }
}
